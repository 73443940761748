<template>
  <div>
    <div class="query">
      <a-form-model :labelCol="{span: 8}" :wrapperCol="{span:16}">
        <a-row :gutter="4">
          <a-col :xxl="6" :xl="6" :md="8" :sm="12">
            <a-form-model-item label="要货单号">
              <a-input v-model.trim="queryParam.orderCode" placeholder="要货单号" :max-length="50"></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :xxl="6" :xl="6" :md="8" :sm="12">
            <a-form-model-item label="发货单号">
              <a-input v-model.trim="queryParam.shipCode" placeholder="发货单号" :max-length="50"></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :xxl="6" :xl="6" :md="8" :sm="12">
            <a-form-model-item label="收货人">
              <a-input v-model.trim="queryParam.consignee" placeholder="收货人" :max-length="50"></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :xxl="6" :xl="6" :md="8" :sm="12">
            <a-form-model-item label="所在省市区">
              <PCD placeholder="所在省市区" :province.sync="queryParam.province" :provinceId.sync="queryParam.provinceId"
              :city.sync="queryParam.city" :cityId.sync="queryParam.cityId"
              :district.sync="queryParam.district" :districtId.sync="queryParam.districtId"></PCD>
            </a-form-model-item>
          </a-col>
          <a-col :xxl="6" :xl="6" :md="8" :sm="12">
            <a-form-model-item label="详细地址">
              <a-textarea v-model.trim="queryParam.street" placeholder="详细地址" :max-length="50"></a-textarea>
            </a-form-model-item>
          </a-col>
          <a-col :xxl="6" :xl="6" :md="8" :sm="12">
            <a-form-model-item :labelCol="{span: 0}" :wrapperCol="{span:22,offset:1}">
              <a-button @click="toSearch(0)" type="primary" icon="search">查询</a-button>
              <a-button @click="toSearch(1)" type="default" icon="sync">重置</a-button>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
    </div>
    <div>
      <a-button @click="onPrint()" type="primary" v-if="isPrint">打印</a-button>
      <a-button @click="toHandler(null,'info')" type="primary" v-if="isCheck">查看</a-button>
      <a-button @click="toHandler(null,'ship')" type="primary" v-if="isLogistics">完善物流信息</a-button>
      <downLoad method="get" api="/api/order/system/orderShipInfo/exportOrderShipInfo" v-if="isExport" :params="queryParam" name="发货单列表.xlsx">批量导出订单</downLoad>
      <downLoad v-if="selectedRowKeys.length > 0 && isOrderDetail" method="get" api="/api/order/system/orderShipInfo/exportOrderShipInfoDetail" :params="getRowId" name="发货单明细.xlsx">导出订单明细</downLoad>
    </div>
    <div class="table">
      <a-table :data-source="tableData" :row-selection="rowSelection" :rowKey="record => record.id"  bordered :customRow="changeTableRow" :pagination="page" @change="p=>{page=p;toSearch(2)}">
        <a-table-column title="发货单号" data-index="shipCode" :width="210" :ellipsis="true" align="left" fixed='left'>
          <template slot-scope="text">
            <a-tooltip><template slot="title"> {{text}} </template> {{text}} </a-tooltip>
          </template>
        </a-table-column>
        <a-table-column title="要货单号" data-index="orderCode" :width="210">
        </a-table-column>
        <a-table-column title="件数" data-index="caseNumber" :width="80" >
        </a-table-column>
        <a-table-column title="总重量" data-index="grossWeight" :width="80">
        </a-table-column>
        <a-table-column title="运费" data-index="freight" :width="80">
        </a-table-column>
        <a-table-column title="收货人" data-index="consignee" :width="210" :ellipsis="true" align="left">
        </a-table-column>
        <a-table-column title="收货人电话" data-index="phone" :width="120" :ellipsis="true" align="left">
        </a-table-column>
        <a-table-column title="地址" data-index="street" :width="450" :ellipsis="true" align="left">
          <template slot-scope="text, record">{{record.province}}{{record.city}}{{record.district}}{{record.street}}</template>
        </a-table-column>
        <a-table-column title="生成方式" data-index="sourceType" :width="80" :ellipsis="true" align="left">
          <template slot-scope="text">{{text|sourceType}}</template>
        </a-table-column>
        <a-table-column title="发货时间" data-index="createTime" :width="210" :ellipsis="true" align="left">
        </a-table-column>
        <!-- <a-table-column title="操作" data-index="action" :width="164" :ellipsis="true" align="left" >
          <template slot-scope="text, record">
            <a class="a_link" @click="toHandler(record,'info')">查看</a>
            <a class="a_link" @click="toHandler(record,'ship')">完善物流信息</a>
          </template>
        </a-table-column> -->
      </a-table>
    </div>
    <a-modal :maskClosable="false" :title="title" style="top: 8px;" :width="800" v-model="visible" :confirmLoading="isLoading"
      @cancel="toCancel" :footer="null">
      <a-table size="small" :data-source="tableData2" bordered rowKey="id" :pagination="false">
        <a-table-column title="序号" data-index="index" :width="60" align="center" :customRender="(text,record,index)=>{return index+1}">
        </a-table-column>
        <a-table-column title="发货单号" data-index="shipCode" :width="140" :ellipsis="true" align="left">
        </a-table-column>
        <a-table-column title="物流信息" data-index="remark" :width="200" :ellipsis="true" align="left">
        </a-table-column>
        <a-table-column title="操作时间" data-index="time" :width="140" :ellipsis="true" align="left">
        </a-table-column>
        <a-table-column title="操作" data-index="action" :width="60" :ellipsis="true" align="center">
          <template slot-scope="text, record">
            <a class="a_link" @click="toHandler(record,'delShip')">删除</a>
          </template>
        </a-table-column>
      </a-table>
      <div style="margin-top: 14px;">
        <a-form-model ref="form" :model="form" :labelCol="{span: 4}" :wrapperCol="{span:20}">
          <a-row :gutter="4">
            <a-col :md="24">
              <a-form-model-item label="物流信息" prop="remark" :rules="[{required:true,message:'请输入物流信息',trigger:'blur'}]">
                <a-textarea placeholder="请输入物流信息" v-model.trim="form.remark"></a-textarea>
              </a-form-model-item>
              <a-form-model-item label="附件">
                <QiniuUpload type="image" :value.sync="form.imgUrl" :size="3"></QiniuUpload>
                <div class="upload-hint-text">支持扩展名：.jpg .png</div>
              </a-form-model-item>
            </a-col>
          </a-row>
          <div style="text-align: right;margin-top: 14px;">
            <a-button type="primary" @click="toSubmit">确认新增物流</a-button>
          </div>
        </a-form-model>
      </div>
    </a-modal>
    <a-modal title="打印预览" width="80%"  v-model="putVisible" :confirmLoading="putloading" :footer="null" :maskClosable="false">
      <div id="printwrap">
          <template>
<!--            <div class="printDiv" style="width: 100%; page-break-before: auto; page-break-after: always">-->
              <div class="printDiv" style="width: 100%; page-break-before: auto; ">
              <div class="printTab01" style="display: flex;justify-content: space-between;padding-bottom: 5px;border-bottom: 1px solid #000;">
                <div class="div1">
                  <!-- <div class="logo-msg" style="display: flex;align-items: center;height: 60px;">
                    <div class="logo"><img :src="bsLogoImg" width="150" style="max-height:60px;" alt=""></div>
                  </div> -->
                </div>
                <div class="div2" style="display: flex">
                  <!-- <div class="text" style="margin: 0 3pt;width: 120px;text-align: center;">
                    <div><img :src="bsWxImg" width="60" height="60" alt=""></div>
                    <div class="mt5" style="font-size: 12px;line-height: 20px;">微信公众号</div>
                  </div> -->
                </div>
                <div class="div3">
                  <div style="height: 60px;" id="qrcode"></div>
                  <!-- <div class="mt5" style="text-align: center;font-weight: 600; margin-top: 3px; font-size: 14px;">{{printOrderInfoList.orderCode}}</div> -->
                </div>
              </div>
              <div class="printTab02">
                <table style="width: 100%; table-layout: fixed;" align="center" class="protable" border="0" cellpadding="0" cellspacing="0" >
                  <thead>
                  <tr>
                    <th style="white-space: nowrap; padding: 5px 10px;border-bottom: 1px solid #000;font-weight: bold;font-size: 13px; width: 150px;">商品名称</th>
                    <th style="white-space: nowrap; padding: 5px 10px;border-bottom: 1px solid #000;font-weight: bold;font-size: 13px; width: 110px;">规格</th>
                    <th style="white-space: nowrap; padding: 5px 10px;border-bottom: 1px solid #000;font-weight: bold;font-size: 13px;">单位</th>
                    <th style="white-space: nowrap; padding: 5px 10px;border-bottom: 1px solid #000;font-weight: bold;font-size: 13px;">购买量</th>
                    <th style="white-space: nowrap; padding: 5px 10px;border-bottom: 1px solid #000;font-weight: bold;font-size: 13px;">商品单价</th>
                    <th style="white-space: nowrap; padding: 5px 10px;border-bottom: 1px solid #000;font-weight: bold;font-size: 13px;">促销价</th>
                    <th style="white-space: nowrap; padding: 5px 10px;border-bottom: 1px solid #000;font-weight: bold;font-size: 13px;">商品金额</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(data, index) in printOrderInfoList.infoDetailsAndGoods" :key="index">
                    <td style="padding: 4px 10px; vertical-align: middle; font-size: 12px; width: 150px;">{{data.goodsName}}</td>
                    <td style="padding: 4px 10px; vertical-align: middle; font-size: 12px; width: 110px;">{{data.specStr}}</td>
                    <td style="padding: 4px 10px; vertical-align: middle; font-size: 12px;">{{data.proUnit}}</td>
                    <td style="padding: 4px 10px; vertical-align: middle; font-size: 12px;">{{data.demandNum}}</td>
                    <td style="padding: 4px 10px; vertical-align: middle; font-size: 12px;">&yen;{{data.discountBeforePrice}}</td>
                    <td style="padding: 4px 10px; vertical-align: middle; font-size: 12px;">&yen;{{data.price}}</td>
                    <td style="padding: 4px 10px; vertical-align: middle; font-size: 12px;">&yen;{{data.demandNum * data.price}}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
              <div class="printTab03">
                <table style="width: 100%;" align="center" class="protable" border="0" cellpadding="0" cellspacing="0">
                  <thead>
                  <tr>
                    <th style="padding: 5px 10px; border-top: 1px solid #000;font-weight: bold;font-size: 13px;">订单金额</th>
                    <th style="padding: 5px 10px; border-top: 1px solid #000;font-weight: bold;font-size: 13px;">优惠金额</th>
                    <th style="padding: 5px 10px; border-top: 1px solid #000;font-weight: bold;font-size: 13px;">运费金额</th>
                    <th style="padding: 5px 10px; border-top: 1px solid #000;font-weight: bold;font-size: 13px;">应收金额</th>
                    <th style="padding: 5px 10px; border-top: 1px solid #000;font-weight: bold;font-size: 13px;">实收金额</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td style="padding: 0px 10px 5px 10px; border-bottom: 1px solid #000; font-size: 12px;">&yen;{{printOrderInfoList.totalAmount}}</td>
                    <td style="padding: 0px 10px 5px 10px; border-bottom: 1px solid #000; font-size: 12px;">&yen;{{printOrderInfoList.totalAmount - printOrderInfoList.payAmount}}</td>
                    <td style="padding: 0px 10px 5px 10px; border-bottom: 1px solid #000; font-size: 12px;">&yen;--</td>
                    <td style="padding: 0px 10px 5px 10px; border-bottom: 1px solid #000; font-size: 12px;">&yen;--</td>
                    <td style="padding: 0px 10px 5px 10px; border-bottom: 1px solid #000; font-size: 12px;">&yen;{{printOrderInfoList.payAmount}}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
              <div class="printTab04">
                <table style="width: 100%; table-layout: fixed" align="center" class="protable" border="0" cellpadding="0" cellspacing="0">
                  <tbody>
                  <tr>
                    <td style="padding: 2px 10px; font-size: 12px; width: 170px;">收货人：{{printOrderInfoList.customerName}}</td>
                    <td style="padding: 2px 10px; font-size: 12px; width: 170px;">联系方式：{{printOrderInfoList.mobile}}</td>
                    <td style="padding: 2px 10px; font-size: 12px;">支付情况：暂无</td>
                  </tr>
                  <tr>
                    <td style="padding: 2px 10px 4px 10px; border-bottom: 1px solid #000; font-size: 12px;" colspan="2">收货地址：{{printOrderInfoList.customerArea}}{{printOrderInfoList.address}}</td>
                    <td style="padding: 2px 10px 4px 10px; border-bottom: 1px solid #000; font-size: 12px;">订单备注：{{printOrderInfoList.orderRemark}}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
              <div class="printTab06">
                <table style="width: 100%;" align="center" class="protable" border="0" cellpadding="0" cellspacing="0">
                  <tbody>
                  <tr>
                    <td style="padding: 2px 10px 4px 10px; font-size: 12px;">快递公司名称：</td>
                    <td style="padding: 2px 10px 4px 10px; font-size: 12px;">快递单号：</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </template>
        </div>
        <div class="" align="center">
          <a-button type="primary" v-print="'#printwrap'" align="center">立即打印</a-button>
        </div>
    </a-modal>
    <OrderInfoModal ref="orderInfoModal"></OrderInfoModal>
  </div>
</template>
<script src='../../../static/js/lib/jquery.print.js'></script>
<script>
  const qs = require('qs')
  import QRCode from 'qrcodejs2'
  import { checkPermission } from '@/utils/permissions'

  export default {
    data() {
      return {
        putloading: false,
        putVisible: false,
        queryParam: {},
        value: '',
        resetQueryParam: null,
        tableData: [],
        selectedRowKeys: [],
        selectedRows: [],
        loading: false,
        page: {
          current: 1,
          pageSize: 10,
          total: 0
        },
        visible: false,
        title: "物流信息",
        form: {},
        isLoading: false,
        tableData2: [],
        row: null,
        printOrderInfoList: {},
        isPrint: checkPermission('ordersend:shippingOrder:printing'),
        isOrderDetail: checkPermission('ordersend:shippingOrder:exportOrderDetailed'),
        isExport: checkPermission('ordersend:shippingOrder:exportOrder'),
        isLogistics: checkPermission('ordersend:shippingOrder:logistics'),
        isCheck: checkPermission('ordersend:shippingOrder:see')
      }
    },
    components: {
      OrderInfoModal
    },
    computed: {
      rowSelection() {
        const _this = this
        return {
          fixed: true,
          type: 'radio', //"radio"
          selectedRowKeys: this.selectedRowKeys,
          onChange: (selectedRowKeys, selectedRows) => {
            _this.selectedRowKeys = selectedRowKeys
            _this.selectedRows = selectedRows
            console.log(_this.selectedRowKeys)
          }
        }
      },
      getRowId() {
        return {id: this.selectedRowKeys[0]}
      }
    },
    methods: {
      toSearch(type) {
        if (type == 1) {
          this.resetQueryParam ? this.queryParam = Object.assign({}, this.resetQueryParam) : this.resetQueryParam =
            Object.assign({}, this.queryParam)
        }
        this.queryParam.pageNumber = type == 2 ? this.page.current : this.page.current = 1
        this.queryParam.pageSize = this.page.pageSize
        const postData = Object.assign({}, this.queryParam, this.page)
        this.axios.get(`/api/order/system/orderShipInfo/list?${qs.stringify(postData)}`).then(res => {
          this.tableData = res.body.records
          this.page.total = res.body.total
        }).catch(err => {})
      },
      toCancel() {
        this.$refs.form && this.$refs.form.resetFields()
        this.visible = false
      },
      toSearch2() {
        let postData = {
          shipCode: this.selectedRows[0].shipCode,
          orderCode: this.selectedRows[0].orderCode,
          logisticsCode: this.selectedRows[0].deliveryNum
        }
        this.axios.get(`/api/order/system/orderShipLogistics/list?${qs.stringify(postData)}`).then(res => {
          this.tableData2 = res.body.logisticsList
          this.form.imgUrl = res.body.imgUrl
        })
      },
      // 打印
      onPrint(item) {
        if (this.selectedRows.length === 0) {
          this.$notification.info({
              message: '请选择一条记录'
          })
          return
        }
        this.axios.get(`/api/order/system/orderInfo/selectByCode?orderCode=${this.selectedRows[0].orderCode}`).then(res => {
          this.putloading = false;
          this.putVisible = true;
          this.printOrderInfoList = res.body;
          this.$nextTick(function () {
            document.getElementById("qrcode").innerHTML = "";
            let qrcode = new QRCode("qrcode", {
              width: 60,
              height: 60,
              text: res.body.orderCode,
              colorDark: "#109dff",
              colorLight: "#d9d9d9"
            });
          });
        }).catch(error => {
          this.putloading = false;
        });
      },
      /**
       * 增、查、删（存在type）
       */
      toHandler(row, name) {
        if(this.selectedRows.length <= 0){
          return this.$message.warning('请选择一条数据')
        }
        switch (name) {
          case 'info':
            this.$refs.orderInfoModal.showInfo(this.selectedRows[0].orderCode, this.selectedRows[0].shipCode, 'shipOrder', this.selectedRowKeys[0])
            // this.$refs.orderInfoModal.showInfo(row.orderCode,row.shipCode)
            break;
          case 'ship':
            this.visible = true
            this.form = {}
            this.row = row
            this.toSearch2()
            break;
          case 'info':
            this.visible = true
            this.form = {}
            this.row = row
            this.toSearch2()
            break;
          case 'delShip':
            this.$confirm({
              title: '确定删除该条记录',
              onOk: () => {
                this.axios.post(`/api/order/system/orderShipLogistics/del/${row.id}`).then(res => {
                  this.$message.success(res.message)
                  this.form = {}
                  this.toSearch2(0)
                }).catch(err => {})
              }
            })
            break;
        }
      },
      /**
         * 点击行选中
         */
        changeTableRow(record) {
          return {
            on: {
              click: e => {
                if (this.selectedRowKeys[0] === record.id) {
                  this.selectedRowKeys = []
                  this.selectedRows = []
                } else {
                  this.selectedRowKeys = [record.id]
                  this.selectedRows = [record]
                }
              }
            }
          }
        },
      /**
       * 提交
       */
      toSubmit() {
        let tmp = this.form
        this.$refs["form"].validate(valid => {
          if (valid) {
            let postData = Object.assign({}, tmp, {
              shipCode: this.selectedRows[0].shipCode
            })
            this.isLoading = true
            this.axios.post(`/api/order/system/orderShipLogistics/add?${qs.stringify(postData)}`).then(res => {
              this.$message.success(res.message)
              this.toSearch2()
              this.form = {}
              this.visible = false
            }).catch(err => {}).finally(() => {
              this.isLoading = false
            })

          }

        })}
    },
    created() {
      this.toSearch(1)
    },

  }
</script>


<style lang="less" scoped>

</style>
